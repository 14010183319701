#menu--main {
	background-color: #FFFFFF;
	.menu {
		//transition: all ease .5s;
		//transition: all 0.3s;
		nav {
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			transition: all 0.1s;
			.brand {

				.ico {
					//transition: all ease .5s;
					background-image: url('./img/ico.png');
					width: 4.375rem;
					height: 3.75rem;
					float: left;
					margin-right: 1.25rem;
					padding-right: 1.25rem;
					border-right: 1px solid $second_color;
					background-repeat: no-repeat;
					background-size: contain;
				}

				.text {
					float: left;
					//transition: all ease .5s;
					h2.title {
						font-family: 'roboto_condensedlight';
						font-family: 'Roboto Slab', serif;

						font-weight: 400;
						font-size: 1.25rem;
						color: $second_color;
						margin-bottom: 0;
						//transition: all ease .5s;
					}
					h2.name {
						font-family: 'Roboto Slab', serif;
						font-weight: 700;
						color: $main_color;
						//font-family: 'roboto_condensedlight';
						font-size: 1.8rem;
						//color: #3c4858;
						//transition: all ease .5s;
					}
				}

			}

			ul {

				li {

					margin: 0 1rem;
					//transition: all ease .5s;
					a {
						color: #3a3a3a;
						font-size: 0.95rem;
						//text-transform: uppercase;
						font-family: 'Raleway',sans-serif;
						font-weight: 400;
						//transition: all ease 5s;
					}


				}

				li.active {
					a {
						color: $main_color;

						font-family: 'Roboto Slab', serif;
						font-weight: 700;
					}
				}
			}
		}
	}

}

section#menu.small {

	#menu--main {
		//position: fixed;
		//top: 0;
		//left: 0;
		//z-index: 99999;
		//width: 100%;
		////transition: .3s all ease;
		//background-color: #FFFFFF;


	}
}

@media only screen and (max-width : 1023px) {

	.button_container {
		position: fixed;
		top: 5%;
		right: 2%;
		height: 27px;
		width: 35px;
		cursor: pointer;
		z-index: 100;
		-webkit-transition: opacity .25s ease;
		transition: opacity .25s ease;

	}
	.button_container:hover {
		opacity: 1;
	}
	.button_container.active .top {
		-webkit-transform: translateY(11px) translateX(0) rotate(45deg);
		transform: translateY(11px) translateX(0) rotate(45deg);
		background: #FFF;
	}
	.button_container.active .middle {
		opacity: 0;
		background: #FFF;
	}
	.button_container.active .bottom {
		-webkit-transform: translateY(-11px) translateX(0) rotate(-45deg);
		transform: translateY(-11px) translateX(0) rotate(-45deg);
		background: #FFF;
	}
	.button_container span {
		//background: #FF5252;
		background-color: $main_color;
		border: none;
		height: 3px;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		-webkit-transition: all .35s ease;
		transition: all .35s ease;
		cursor: pointer;
	}
	.button_container span:nth-of-type(2) {
		top: 11px;
	}
	.button_container span:nth-of-type(3) {
		top: 22px;
	}

	.overlay {
		position: fixed;
		background: #FF5252;
		background-color: $main_color;
		top: 0;
		left: 0;
		width: 100%;
		height: 0%;
		opacity: 0;
		visibility: hidden;
		-webkit-transition: opacity .35s, visibility .35s, height .35s;
		transition: opacity .35s, visibility .35s, height .35s;
		overflow: hidden;
		z-index: 90;

		nav.navbar {

			.brand {
				display: none;
			}

		}

		ul {
			li {
				height: 18%;
				a {
					color: #FFFFFF !important;
					font-size: 1.6rem !important;
				}
			}
		}
	}
	.overlay.open {
		opacity: .9;
		visibility: visible;
		height: 100%;
	}
	.overlay.open li {
		-webkit-animation: fadeInRight .5s ease forwards;
		animation: fadeInRight .5s ease forwards;
		-webkit-animation-delay: .35s;
		animation-delay: .35s;
	}
	.overlay.open li:nth-of-type(2) {
		-webkit-animation-delay: .4s;
		animation-delay: .4s;
	}
	.overlay.open li:nth-of-type(3) {
		-webkit-animation-delay: .45s;
		animation-delay: .45s;
	}
	.overlay.open li:nth-of-type(4) {
		-webkit-animation-delay: .50s;
		animation-delay: .50s;
	}
	.overlay nav {
		position: relative;
		height: 70%;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		font-size: 50px;
		font-family: 'Vollkorn', serif;
		font-weight: 400;
		text-align: center;
	}
	.overlay ul {
		list-style: none;
		padding: 0;
		margin: 0 auto;
		display: inline-block;
		position: relative;
		height: 100%;
	}
	.overlay ul li {
		display: block;
		height: 25%;
		height: calc(100% / 4);
		min-height: 50px;
		position: relative;
		opacity: 0;
	}
	.overlay ul li a {
		display: block;
		position: relative;
		color: #FFF;
		text-decoration: none;
		overflow: hidden;
	}
	.overlay ul li a:hover:after, .overlay ul li a:focus:after, .overlay ul li a:active:after {
		width: 100%;
	}
	.overlay ul li a:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 0%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		height: 3px;
		background: #FFF;
		-webkit-transition: .35s;
		transition: .35s;
	}

	@-webkit-keyframes fadeInRight {
		0% {
			opacity: 0;
			left: 20%;
		}
		100% {
			opacity: 1;
			left: 0;
		}
	}

	@keyframes fadeInRight {
		0% {
			opacity: 0;
			left: 20%;
		}
		100% {
			opacity: 1;
			left: 0;
		}
	}
}