html {
  font-size: 16px;
  height: 100%;

}

body {
  color:#5a5a5a;
  font-weight: 400;
  background-color: #FFFFFF;
  font-family: 'Raleway',sans-serif;
  line-height: 1.6rem;
  font-size: 1rem;

}

a {
  color: $main_color;
  color: #FFFFFF;
  font-weight: 500;
  &:hover {
    text-decoration: none;
    color: #d5233f;
  }
}

b,strong {
  color: #000;
  color: #FFFFFF;
}

@media only screen and (max-width : 1200px) {
  html {
    font-size: 8px;
  }
}

li.item-101 {
  display: none;
}

.label {
  color: #1a1a1a;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Poppins',sans-serif;
  font-size: 0.8rem;
  margin-left: 0.2rem;
  margin-bottom: 2rem;
}

b, strong {
  color: #1a1a1a;
}

a {
  color: #15479c;
  font-weight: 500;
}

nav h2.name {
  margin: 0;
}