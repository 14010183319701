#menu--main {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15) !important;
  margin-bottom: 5rem;
}

//.small {
//  #menu--main {
//    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15) !important;
//  }
//}

#menu--main {

}