@mixin title() {
  font-size: 3.7rem;
  color: #1a1a1a;
  font-family: 'Poppins',sans-serif;
  font-weight: 600;
  line-height: 3.7rem;
}

@mixin title_page() {
  font-size: 2.2rem;
  color: #1a1a1a;
  font-family: 'Poppins',sans-serif;
  font-weight: 600;
}

@mixin subtitle() {
  font-size: 1.2rem;
  line-height: 1.67rem;
  font-family: 'Poppins',sans-serif;
  color: #1a1a1a;
  font-weight: 600;
}

@mixin container--content() {

  //margin: 4rem 1.875rem 0;

  margin-top: 0;
  //border-radius: 6px;
  //box-shadow: 0 16px 24px 2px rgba(0,0,0,.14), 0 6px 30px 5px rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2);
  //background-color: #3a66af;
  position: relative;
  padding-top: 1rem;
  margin-bottom: 1rem;
  //width: 76%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 8rem;
}