#slider {
    //background-image: url('http://localhost/notariuszpm/images/1.jpg');
    //background: rgba(0,0,0,.5);
    height: 32rem;




  .col-12 {

    padding-left: 0;
    padding-right: 0;

    .carousel-item {
      background:linear-gradient(to bottom right ,rgba(21,71,156,0.1) 10%,rgba(21,71,156,0.4)),url('../../../images/3.jpg');
      xbackground: url('../../../images/3.jpg');
      height: 32rem;
      background-size: cover;
      background-repeat: no-repeat;

    .carousel-caption {


      width: fit-content;
      margin-right: auto;
      text-align: left;
      padding: 0;

      margin-left: 8rem;
      margin-bottom: 9rem;


      h3 {

        color: #FFFFFF;
        font-size: 2.8125rem;
        line-height: 3.4125rem;
        font-family: 'Roboto Slab', serif;
        font-weight: 700;
        padding: 0;
        margin: 0;

      }

    }

  }

  }

}