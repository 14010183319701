section.component {

  .container {
    padding-left: 30px;
    padding-right:30px;

  }

  @include container--content();
  margin-top: 3rem;

  h1 {
    @include title_page();
    margin: 0 0 4rem 0;

  }


  h2 {
    @include title();
    margin: 1.2rem 0;
    margin-bottom: 4rem;
  }


  h4 {
    @include subtitle();
    margin-bottom: 1rem;
  }

  .nav-tabs {
    border-bottom: none;

    li {
      width: 100%;
      margin-bottom: 0.2rem;
    }
  }

  .page-header {
       h1 {
         display: none;
       }
  }

  .category-desc {
    margin-top: 3rem;
  }

}