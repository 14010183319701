.menu--mobile {
  display: none;
}


/*==========  Non-Mobile First Method  ==========*/
/* Ultrabook */
@media only screen and (max-width : 1366px) {

  html {
    font-size: 14px;
  }


  #intro {
    margin-top: 0rem;
  }

  #intro, section.component {
    width: 96%;
  }

  #intro .img-intro- {
    margin-top: 2rem;
    height: 24rem;
  }

  #slider .col-12 .carousel-item {
    height: 30rem;
  }

  #slider .col-12 .carousel-item .carousel-caption {

    margin-left: 0;
    margin-bottom: 10rem;
  }

  section.component, #intro {

    .container {

      padding-left: 15px;
      padding-right: 15px;

    }
  }

  #menu--main {
    padding: .5rem 0;
  }

  footer {
    .row {
      padding-left: 0;
      padding-right: 0;
    }
  }

}




/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {

}


@media only screen and (max-width : 1024px) {

  body {
    font-size: 1.1rem;

  }

  section.component, #intro {
    padding-top: 0;
    padding-bottom: 5rem;
  }




}


/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

  #intro, section.component {
    width: 96%;
  }

  .container {
    width: 98%;

  }

  .button_container {

    top: 2.3rem;
    right: 2.2rem;

    span {
      background-color: $main_color;
      opacity: 1;
    }

  }

  #intro .img-intro- {
    margin-top: 4rem;
    height: 20rem;
  }

  .menu--mobile {
    display: block;
  }



  section.component, #intro {
    margin-top: 4rem;
  }


    section#menu {

      //position: fixed;
      //width: 100%;
      //height: 10rem;
      //z-index: 5;
      //top: 0rem;
      //
      //#menu--main {
      //  position: fixed;
      //  width: 100%;
      //  z-index: 5;
      //}
  }

  #menu--main {
    margin-bottom: 1rem;
  }

}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {


  body {
    line-height: 1.9rem;
  }

  #intro h1 {
    width: 100%;
  }

  #menu--main .menu nav ul li a {
    font-size: 1.3rem;
  }

  #menu--main .menu nav ul li.active a:after {
    background: transparent !important;
  }

  .container {
    max-width: 98%;

  }

  .overlay {

    background-color: $main_color;

    nav.navbar {

      .brand {
        display: none;
      }

    }

    ul {
      li {
        height: 18%;
        a {
          color: #FFFFFF !important;
        }
      }
    }

  }

  #menu--main .menu nav ul li.active a {
    //color: #FF0000 !important;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      height: 3px;
      background: #FFF;
      -webkit-transition: .35s;
      transition: .35s;
      width: 100%;
    }
  }

  footer {
    .row {
      padding-left: 15px;
      padding-right: 15px;
    }
  }


}

@media only screen and (max-width : 736px) {
  .container {
    max-width: 720px;

  }

}


@media only screen and (max-width : 668px) {

  body {

    line-height: 1.925rem;
    font-size: 1.2rem;

  }

  #slider .col-12 .carousel-item .carousel-caption {
    margin-left: -5rem;
    margin-bottom: 10rem;
  }

  .container {
    max-width: 660px;

  }

  .phone-first-order {
    order: 1;
  }

  .phone-second-order {
    order: 3;
  }

}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {


  #carouselExampleIndicators li {
    display: none;
  }

  .container {
    max-width: 460px;

  }

  #slider .col-12 .carousel-item .carousel-caption {
    margin-left: -2rem;
    margin-bottom: 10rem;
  }

  #slider .col-12 .carousel-item .carousel-caption h3 {

    font-size: 2.2rem;
    line-height: 2.6rem;

  }


  #slider .col-12 .carousel-item {
    xxbackground: linear-gradient(to bottom right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url('../../../images/3.jpg');
    background:linear-gradient(to bottom right ,rgba(21,71,156,0.1) 10%,rgba(21,71,156,0.4)),url('../../../images/3.jpg');
    background-size: cover;

  }

  #intro h1, section.component h1 {
    font-size: 2.9rem;
  }


  #intro .img-intro- {
    margin-top: 0rem;
    height: auto;
    width: 100%;
  }

  section.component, #intro {
    padding-bottom: 3rem;

  }



  section.component .nav-tabs li {
    margin-bottom: 1.2rem;
    border-bottom: 1px solid #cecece;
    padding-bottom: 1rem;
  }


  .button_container {
    top: 2.0rem;
    right: 2rem;
  }


  section.component, #intro {
    margin-top: 2rem;
  }

  #intro h1, section.component h1 {
    margin-bottom: 2rem;
  }

  #intro .photo {
    margin-bottom: 2rem;
  }

}

/* Custom, iPhone Retina */
@media only screen and (max-width : 380px) {
  .container {
    max-width: 360px;

  }

  #menu--main .menu nav {
    padding-bottom: 0.5rem;
  }


    footer {
    .container {
      max-width: 340px;
      //padding: 0 15px;
    }
  }

  #slider .col-12 .carousel-item .carousel-caption {

    margin-bottom: 7rem;
  }
}

@media only screen and (max-width : 360px) {
  .container {
    max-width: 350px;

  }


  footer {
    .container {
      max-width: 340px;
    }
  }
}



/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {
  .container {
    max-width: 300px;

  }

  #menu--main .menu nav .brand .text h2.title {
    font-size: 1.1rem;
  }

  #menu--main .menu nav .brand .text h2.name {
    font-size: 1.6rem;
  }

  .button_container {
    top:1.8rem;
  }

  #slider .col-12 .carousel-item .carousel-caption h3 {
    font-size: 1.9rem;
  }

  #slider .col-12 .carousel-item .carousel-caption {

    margin-bottom: 7rem;
  }
}








