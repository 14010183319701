#intro {

  @include container--content();

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  h1 {
    @include title();
    margin: 0 0 3rem 0;
    width: 445px;

  }

  .img-intro- {
    text-align: right;
    margin-top: 4rem;
    border-radius: 0.25rem !important;
    height: 22rem;

    img {
      box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.15) !important;

    }

  }

  img {
    width: 100%;
  }

}