footer {
  padding-top: 1.125rem ;
  padding-bottom: 0.5rem ;
  background-color: #f8f8f8;
  color: #FFFFFF;
  //text-transform: uppercase;
  font-size: 0.85rem;
  color: #3c4858;
  margin-bottom: 1rem;
  font-family: 'Poppins',sans-serif;


  .row {
    padding-left: 15px;
    padding-right: 15px;
  }


  .first {

  }

  .second {
    text-align: center;
  }

  .third {
    text-align: right;
  }

  strong {
    color: #5a5a5a;
    margin-bottom: 1.3rem;
    display: block;
    margin-bottom: 0.3rem;
  }

  a {
    color: #3c4858;
    font-weight: 500;
    &:hover {
      text-decoration: none;
      color: #3c4858;
    }
  }

}