// TEMPLATE STYLES

@import "../vendor/css3-animate-it-master/css/animations.css";
// Font

//@import "../fonts/roboto_condensed/roboto_condensed.css";
//@import "../fonts/roboto/stylesheet.css";
//@import "../fonts/sourcesanspro/stylesheet.css";
// end Font


@import "./style/variable";
@import "./style/fonts";
@import "./style/mixin";
@import "./style/main";
@import "./style/header";
//@import "./style/section";
@import "./style/menu";
@import "./style/slider";
@import "./style/intro";
@import "./style/component";
@import "./style/footer";
@import "./style/mobile";
// custom code